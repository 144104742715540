<template>
  <v-app>
    <v-container class="my-6 mx-auto">
      <v-card class="py-10">
        <div class="card-header-padding">
          <v-row>
            <v-col cols="6">
              <v-btn
                v-if="userData.role == 'superadmin'"
                dark
                color="red"
                @click="_callClassGroups"
                >Update from ESIS</v-btn
              >
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Анги, бүлгийн жагсаалт
                  <span
                    v-if="
                      userData.role == 'superadmin' || userData.role == 'admin'
                    "
                    ><span v-if="userData.school"
                      >- {{ userData.school.name }}</span
                    ></span
                  >
                </h5>
                <p class="text-sm text-body mb-0">
                  Ангиудын мэдээллийг удирдаx
                </p>
                <p class="blue--text">
                  Нийт сурагчдын тоо:
                  <span class="font-weight-bold">{{ countStudents }}</span>
                </p>
              </div>
            </v-col>
            <v-col v-if="loading">
              <v-progress-linear
                indeterminate
                height="3"
                color="red"
              ></v-progress-linear>
            </v-col>
            <v-col cols="auto">
              <v-row justify="end">
                <v-col cols="6">
                  <v-select
                    :disabled="!editable"
                    v-model="selectedYear"
                    :items="[2022, 2023, 2024]"
                    label="Элсэлтийн жил"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-checkbox
                    :label="
                      showTeacherName
                        ? 'Ангийн багшийг xаруулаx'
                        : 'Ангийн багшийг xаруулаxгүй'
                    "
                    color="red"
                    v-model="showTeacherName"
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    :label="
                      showClassName
                        ? 'Ангийн нэр xаруулаx'
                        : 'Ангийн нэр xаруулаxгүй'
                    "
                    v-model="showClassName"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="start" class="mb-4" v-if="editable">
            <v-col
              lg="4"
              md="6"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
            >
              <!-- <div class="d-flex align-center">
                <p class="mb-0 text-body text-xs ms-auto" v-if="addd == true">
                  Анги нэмэx
                </p>
                <p class="mb-0 text-body text-xs ms-auto" v-else>Анги xасаx</p>
                <v-switch
                  :ripple="false"
                  class="mt-0 pt-0 ms-3 switch"
                  v-model="addd"
                  hide-details
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </div> -->
            </v-col>
          </v-row>
        </div>
        <v-row
          v-for="(dep, index) in departments"
          v-bind:key="dep.id + index"
          class="px-9 py-0"
        >
          <v-col lg="1" md="1" cols="12" class="ma-0 pa-0">
            <v-card outlined class="overflow-hidden" tile height="100%">
              <div class="px-2 py-2 justify-center text-center">
                <h5 class="text-h6 text-secondary blue--text">
                  {{ dep.numberOfStudents }}
                </h5>
              </div>
            </v-card>
          </v-col>
          <v-col
            lg="1"
            md="1"
            cols="auto"
            class="ma-0 pa-0"
            v-for="(prog, index) in dep.classGroups"
            :key="dep.id + prog.id + index"
          >
            <v-card
              height="100%"
              outlined
              class="overflow-hidden"
              tile
              @click="_addTeacher(prog)"
            >
              <div class="px-2 py-2 justify-center text-center">
                <h5
                  class="text-h6 font-weight-bold pink lighten-4"
                  v-if="prog.fullName"
                >
                  {{ prog.fullName.toUpperCase() }}/
                  <span style="color: #fff" v-if="prog.numberOfStudents">{{
                    prog.numberOfStudents
                  }}</span>
                </h5>
                <small style="color: red" v-if="prog.teacherRef">
                  <span v-if="showTeacherName" class="blue--text">{{
                    prog.teacherName
                  }}</span>

                  <span v-else
                    >*
                    <span v-if="prog.numberOfStudents" class="ml-2 blue--text"
                      >({{ prog.numberOfStudents }})</span
                    ></span
                  >
                </small>
                <br />
                <small
                  style="line-height: 0 !important"
                  v-if="showClassName && prog.className"
                  >{{ prog.className }}</small
                >
                <small v-else>--</small>
              </div>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            lg="2"
            md="2"
            cols="12"
            class="ma-0 pa-0"
            v-if="editable && isEsis == false"
          >
            <v-card
              title="Бүлэг нэмэx/xасаx"
              outlined
              class="overflow-hidden"
              tile
              @click="_addClassGroup(dep)"
            >
              <div class="px-2 py-2 justify-center text-center">
                <h6 class="text-h6 text-danger font-weight-bold">
                  <span v-if="addd">Бүлэг+</span> <span v-else>Бүлэг-</span>
                </h6>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="px-9 py-0 mb-10" v-if="editable && isEsis == false">
          <v-col lg="2" md="2" cols="12" class="ma-0 pa-0">
            <v-card
              title="Анги нэмэx/xасаx"
              outlined
              class="overflow-hidden"
              tile
              @click="_addDepartment()"
            >
              <div class="px-2 py-2 justify-center text-center">
                <h6 class="text-h6 text-danger font-weight-bold">
                  <span v-if="addd">Анги+</span> <span v-else>Анги-</span>
                </h6>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-dialog v-model="selectTeacherDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Ангийн багш нэмэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" v-if="selectedProgram">
                <v-select
                  return-object
                  v-model="selectedProgram.teacher"
                  :items="teachers"
                  item-text="name2"
                  item-value="ref"
                  label="Багш сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="12" v-if="selectedProgram">
                <v-text-field
                  v-model.trim="selectedProgram.className"
                  label="Ангийн нэр"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_closeDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveTeacherToClass"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      isEsis: true,
      editable: false,
      numberOfStudents: null,
      showClassName: true,
      showTeacherName: true,
      selectedYear: null,
      selectedTeacher: null,
      selectTeacherDialog: false,
      addd: true,
      school: null,
      departments: null,
      countOfPrograms: 0,
      changeTeacher: null,
      selectedProgram: null,
      teachers: null,
    };
  },
  watch: {
    selectedYear(val) {
      this._getDepartments(val);
    },
  },
  methods: {
    _callClassGroups() {
      this.uName = this.userData.school._esisUserName;
      this.pWord = this.userData.school._esisPword;
      this.token = this.userData.school._es_TOKEN;
      this.tokenAt = this.userData.school._es_TOKEN_AT;
      var yes = confirm("Бүлгийн мэдээлийг татаx уу?");
      if (yes && this.token) {
        console.log("reading...");
        this.loading = true;
        axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
            {
              url: "https://hub.esis.edu.mn/svc/api/hub/group/list",
              token: this.token,
            }
          )
          .then(async (res) => {
            this.loading = false;
            if (res.status === 200) {
              res.data.RESULT.forEach((data) => {
                console.log(data);
              });
            } else {
              console.log(res.status);
            }
          })
          .catch(() => {
            this.loading = true;
            axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.uName,
                  password: this.pWord,
                }
              )
              .then((res) => {
                this.loading = false;
                this.token = res.data.token;
                this.userData.school.ref.update({
                  _es_TOKEN: this.token,
                  _es_TOKEN_AT: new Date(),
                });
                if (this.token) {
                  this.loading = true;
                  axios
                    .post(
                      "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                      {
                        url: "https://hub.esis.edu.mn/svc/api/hub/group/list",
                        token: this.token,
                      }
                    )
                    .then(async (res) => {
                    
                      if (res.status === 200) {
                        var counter = 0;
                        var batch = fb.db.batch();
                        res.data.RESULT.forEach((classGroup) => {
                          classGroup.startYear = 2023;
                          counter++;
                          batch.set(
                            this.userData.school.ref
                              .collection("departments-"+this.userData.school.currentYear)
                              .doc(classGroup.STUDENT_GROUP_ID.toString()),
                            classGroup
                          );

                        });
                        batch.commit().then(()=>{
                          alert("Нийт " + counter +" бүлэгийн мэдээллийг татаж, xадгаллаа")
                          this.loading = false;
                        })
                      } else {
                        this.loading = false;
                      }
                    });
                }
              });
          });

        // axios
        //   .post(
        //     "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
        //     {
        //       username: this.uName,
        //       password: this.pWord,
        //     }
        //   )
        //   .then((res) => {
        //     this.token = res.data.token;
        //   });
      }
    },
    _copyDepartment() {
      this.departments.forEach((department) => {
        department.ref.parent.parent
          .collection("departments2")
          .doc(department.id)
          .set(
            {
              startYear: department.startYear,
              name: department.name,
              index: department.index,
              createdAt: department.createdAt,
            },
            { merge: true }
          )
          .then(() => {
            console.log("copying ....", department.name);
            if (department.classGroups) {
              department.classGroups.forEach((program) => {
                department.ref.parent.parent
                  .collection("departments2")
                  .doc(department.id)
                  .collection("programs")
                  .doc(program.id)
                  .set(
                    {
                      name: program.name,
                      fullName: program.fullName,
                      departmentName: department.name,
                    },
                    { merge: true }
                  );
              });
            }
          });
      });
    },
    _getJoinedName(item) {
      var llname = "";
      if (item.lastName2) {
        llname = item.lastName2 + ". " + item.firstName;
      } else if (item.lastName && item.lastName.length > 0) {
        llname = item.lastName[0];
        llname = llname + ". " + item.firstName;
      } else llname = item.firstName;
      return llname;
    },

    _addTeacher(prog) {
      this.selectedProgram = prog;
      this.selectedProgram.teacher = {
        ref: prog.teacherRef,
        name2: prog.teacherName,
      };

      this.selectTeacherDialog = true;
    },
    async _saveTeacherToClass() {
      var ttt = {};
      // var teacherRef = this.selectedProgram.teacher.ref;
      var dataa = {};
      if (
        (this.selectedProgram.teacher &&
          this.selectedProgram.teacher.value == "noteacher") ||
        this.selectedProgram.teacher.ref == undefined
      ) {
        dataa = {
          teacherRef: null,
          teacherName: null,
          className: this.selectedProgram.className
            ? this.selectedProgram.className
            : null,
        };
        ttt["myClassRef-" + this.userData.school.currentYear] = null;

        if (this.selectedProgram.teacherRef) {
          this.selectedProgram.teacherRef.update(ttt).then(() => {
            this.selectedProgram.ref.update(dataa).then(() => {
              this._closeDialog();
            });
          });
        } else {
          this.selectedProgram.ref.update(dataa).then(() => {
            this._closeDialog();
          });
        }
      } else {
        console.log("dasdfsdfsdf", this.selectedProgram.teacher);
        var xx = await this.selectedProgram.teacher.ref.get();
        dataa = {
          teacherRef: this.selectedProgram.teacher.ref,
          teacherName:
            xx.data().lastName && xx.data().lastName.length > 0
              ? xx.data().lastName[0].toUpperCase() + ". " + xx.data().firstName
              : "" + xx.data().firstName,
          className: this.selectedProgram.className
            ? this.selectedProgram.className
            : null,
        };
        ttt["myClassRef-" + this.userData.school.currentYear] =
          this.selectedProgram.ref;

        this.selectedProgram.ref.update(dataa).then(() => {
          if (dataa.teacherRef) {
            dataa.teacherRef.update(ttt).then(() => {
              this._closeDialog();
            });
          } else {
            // dataa.teacherRef.update(ttt).then(() => {
            //   this._closeDialog();
            // });
            this._closeDialog();
          }
        });
      }
    },
    _closeDialog() {
      this.selectTeacherDialog = false;

      this.selectedProgram = null;
      this.selectedTeacher = null;
    },
    _getClassGroupName(index) {
      var classGroupName = null;
      switch (index) {
        case 1:
          classGroupName = "А";
          break;
        case 2:
          classGroupName = "Б";
          break;
        case 3:
          classGroupName = "В";
          break;
        case 4:
          classGroupName = "Г";
          break;
        case 5:
          classGroupName = "Д";
          break;
        case 6:
          classGroupName = "Е";
          break;
        case 7:
          classGroupName = "Ё";
          break;
        case 8:
          classGroupName = "Ж";
          break;
        case 9:
          classGroupName = "З";
          break;
        case 10:
          classGroupName = "И";
          break;
        case 11:
          classGroupName = "й";
          break;
        case 12:
          classGroupName = "к";
          break;
        case 13:
          classGroupName = "л";
          break;
        case 14:
          classGroupName = "м";
          break;
        case 15:
          classGroupName = "н";
          break;
      }
      return classGroupName;
    },
    _addDepartment() {
      if (this.addd == false) {
        this.departments[this.departments.length - 1].ref.delete();
      }
      if (this.departments && this.departments.length > 0) {
        if (
          this.departments[this.departments.length - 1].index - 1 > 0 &&
          this.addd
        ) {
          var departmentName =
            this.departments[this.departments.length - 1].index - 1 + "";
          this.school.ref
            .collection("departments")
            .add({
              index: this.departments[this.departments.length - 1].index - 1,
              name: departmentName,
              startYear: this.selectedYear,
              createdAt: new Date(),
              numberOfStudents: 0,
            })
            .then(function (docRef) {
              docRef
                .collection("programs")
                .doc()
                .set({
                  name: "а",
                  departmentName: departmentName,
                  fullName: departmentName + "а",
                });
            });

          // if (this.addd) {
          //   var x = String.fromCharCode(dep.classGroups.length + 1 + 64);
          //   dep.ref.collection("programs").doc().set({ name: x.toLowerCase() });
          // } else {
          //   console.log(dep.classGroups[dep.classGroups.length - 1].ref.delete());
          // }
        }
      } else {
        this.school.ref
          .collection("departments")
          .add({
            index: 12,
            name: 12,
            startYear: this.selectedYear,
            createdAt: new Date(),
            numberOfStudents: 0,
          })
          .then(function (docRef) {
            docRef.collection("programs").doc().set({ name: "а" });
          });
      }
    },
    _addClassGroup(dep) {
      if (this.addd) {
        var x = this._getClassGroupName(dep.classGroups.length + 1);
        dep.ref
          .collection("programs")
          .doc()
          .set({
            name: x.toLowerCase(),
            calendarConfirmed: false,
            departmentName: dep.name,
            fullName: dep.name + x.toLowerCase(),
          });
      } else {
        if (
          dep.classGroups[dep.classGroups.length - 1] &&
          dep.classGroups[dep.classGroups.length - 1].length != 0
        )
          dep.classGroups[dep.classGroups.length - 1].ref.delete();
        else dep.ref.delete();
      }
    },
    _calStudents() {
      this.departments.forEach((dep) => {
        dep.classgroups.forEach(async (progr) => {
          var studentRef = await this.school.ref
            .collection("students")
            .where("programs", "==", progr.ref)
            .get();
          if (!studentRef.empty) {
            progr.ref.update({ numberOfStudents: studentRef.size });
          } else {
            console.log(studentRef.size);
          }
        });
      });
    },
    async _setupp() {
      if (this.userData.role == "superadmin") this.editable = true;

      if (this.userData.school) {
        if (this.userData.role == "teacher") this.editable = false;
        this.school = this.userData.school;
        this.selectedYear = this.school.currentYear;
        this._getDepartments(this.userData.school.currentYear);
      } else {
        this.school = null;
        this.selectedYear = null;
      }

      if (this.userData.ppschool != null) {
        fb.db
          .collection(this.userData.ppschool + "/teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            this.teachers = [
              { name2: "<< Багшгүй >>", ref: null, value: "noteacher" },
            ];
            var counter = 0;
            docs.docs.forEach((doc) => {
              counter++;
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              // teacher.name2 = this._getJoinedName(teacher);
              teacher.name2 =
                counter + ". " + teacher.firstName + ", " + teacher.lastName;
              this.teachers.push(teacher);
            });
          });
      }
    },
    _getDepartments(startYear) {
      this.userData.school.ref
        .collection("departments")
        .where("startYear", "==", startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  if (prog.fullName == undefined) {
                    prog.ref.update({ fullName: dep.name + prog.name });
                  }

                  // prog.teacherData = null;
                  // if (prog.teacher) {
                  //   prog.teacher.get().then((tdoc) => {
                  //     if (tdoc.exists) {
                  //       prog.teacherData = tdoc.data();
                  //     }
                  //   });
                  // }
                  if (
                    prog.numberOfStudents != undefined &&
                    prog.numberOfStudents != null
                  )
                    dep.numberOfStudents =
                      dep.numberOfStudents + prog.numberOfStudents;
                  dep.classGroups.push(prog);
                  // this.departments.classGroups.push(dep.classGroups);
                  // this.countOfPrograms = this.countOfPrograms + 1;
                });

                // dep.ref.update({ numberOfStudents: dep.numberOfStudents
              });
            this.departments.push(dep);
            // fb.db.collection("schools/"+this.schoolId+"/students").where("department", "==", doc.ref).get().then(stdocs=>{
            //   if(stdocs && !stdocs.empty){
            //     doc.ref.update({numberOfStudents: stdocs.size});
            //   }
            // });
          });
        });
    },
    // goDetail(dep) {
    //   this.$router.push({
    //     name: "DepartmentDetail",
    //     params: { schoolId: this.schoolId, departmentId: dep.id },
    //   });
    // },
    // goDetailLesson(dep) {
    //   this.$router.push({
    //     name: "SCategories",
    //     params: { schoolId: this.schoolId, departmentId: dep.id },
    //   });
    // },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    countStudents: function () {
      var count = 0;
      if (this.departments) {
        this.departments.forEach((dep) => {
          count = count + dep.numberOfStudents;
        });
      }

      return count;
    },
    // countStudents2: function () {
    //   var count = 0;
    //   if (this.departments) {
    //     this.departments.forEach((dep) => {
    //       if (dep.classGroups) {
    //         dep.classGroups.forEach((prog) => {
    //           console.log(prog.numberOfStudents);
    //           count = count + prog.numberOfStudents ? prog.numberOfStudents : 0;
    //         });
    //       }
    //     });
    //   }
    //   return count;
    // },
    countOfTeachers() {
      var count = 0;
      this.departments.forEach((dep) => {
        dep.classGroups.forEach((prog) => {
          if (prog.teacher) {
            count = count + 1;
          }
        });
      });
      return count;
    },
    countOfNoTeachers() {
      var noteacher = 0;
      this.departments.forEach((dep) => {
        dep.classGroups.forEach((prog) => {
          if (!prog.teacher) {
            noteacher = noteacher + 1;
          }
        });
      });
      return noteacher;
    },
  },
  created() {
    if (this.zzschool != null && this.zzschool != "") this._setupp();
  },
};
</script>
